
export default{

    data(){

        return{

           

        }

    },

    methods: {
        
        //获取报备状态翻译

        getApprovalStatusText(type){

            return this.$store.state.userInfo?.orderStatusTextObj[type];

        }

    },

}