<template>
   <div class="error-pagewrapper">
       <van-empty image="error" description="该页面不存在,请点击此处返回首页" @click.native="gotoIndex" v-if="$store.state.userInfo.ready && this.$route.path !='/'" />
   </div>
</template>

<script>
export default {

    methods:{

        gotoIndex(){

            this.$router.replace({name:"index"});

        }

    }

}
</script>

<style scoped lang="less">
.error-pagewrapper{

    height:100%;

    padding-top:10vh;

}
</style>
