<template>
   <div class="order-list-wrapper">
       <div class="flex-box-column order-list-cover">
           <div class="hd">
               <van-nav-bar 
               left-arrow 
               safe-area-inset-top 
               right-text="搜索" 
               @click-right="onSearch" 
               class="search" 
               @click-left="back">
                <template #title>
                        <van-search v-model="keyword" class="sm border" shape="round" placeholder="请输入关键字搜索客户" @search="onSearch" ref="orderListSearch"/>
                    </template>
                </van-nav-bar>
                <div class="menu border_bottom z-index-10">
                    <van-dropdown-menu class="z-index-10" :close-on-click-outside="false" ref="orderListMenu">
                        <van-dropdown-item v-model="flowPathValue" @change="flowPathChange" :options="orderStatusOptions" :title="flowPathTitle"/>
                        <van-dropdown-item ref="order_date" :title="orderTimeTitle">
                            <van-cell center :title="item.text" @click="selectDate(item)" v-for="(item,index) in option2" :title-class="{active:orderTimeIndex == index}" :key="index"/>
                            <div class="date-interval">
                                <div class="date-interval-item" @click="calendarOpen">{{orderStartTime ? orderStartTime :'开始时间'}}</div>
                                <div class="g">一</div>
                                <div class="date-interval-item" @click="calendarOpen">{{orderEndTime ? orderEndTime :'结束时间'}}</div>
                                <button 
                                class="confirm date-interval-item" 
                                @click="selectDate({text:'自选时间',value:99})"
                                :class="{live:this.orderStartTime && this.orderEndTime}"
                                >确定</button>
                            </div>
                        </van-dropdown-item>
                        <van-dropdown-item v-model="staffValue" @change="staffChange" :options="getStaffList" :title="staffTitle" v-if="isSubDistributor"/>
                    </van-dropdown-menu>
                </div>
           </div>
           <div class="bd" ref="orderListScrollView">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :disabled="listError && orderList.length == 0">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    :offset="100"
                    :error.sync="listError"
                    error-text="请求失败，点击重新加载"
                    @load="onLoad"
                    >
                    <div class="order-item van-hairline--top-bottom"  @click="linkTo(item)" v-for="(item,index) in orderList" :key="index">
                            <div class="order-item-cover">
                                <div class="order-item-l avatar secret" :class="{man:item.sex == 1,woman:item.sex==2}"></div>
                                <div class="order-item-r">
                                    <div class="info van-hairline--bottom">
                                        <div class="customer">
                                            <div class="name">{{item.name || '客户'}}<span class="phone">{{item.phone}}</span></div>
                                            <!-- 时间判断 是否为报备无效以及退房 -->
                                            <div class="order-time">
                                                {{item.create_at}}
                                            </div>
                                        </div>
                                        <div class="house-name">{{item.houseName}}</div>
                                        <div class="visit-date"><div class="color-999">最后跟进时间：{{item.updatedAt}}</div></div>           
                                        <div class="visit-date"><div class="color-999">客户地区：{{item.area}}</div></div>           
                                        <div class="visit-date"><div class="color-999">线索来源：{{item.typeTxt ? item.typeTxt : '-'}}</div></div>           
                                    </div>
                                    <div class="des">
                                        <div class="order-type">
                                            <div class="order-type-status">
                                                <span :class="{success:item.status==1 || item.status==2,faild:item.status == 3,await:item.status==0}">{{getApprovalStatusText(item.status)}}</span>
                                            </div>
                                            <div class="button-cover">
                                                
                                            </div>
                                        </div>
                                        <div class="order-extra">
                                            <p v-if="item.approvalResult" class="orderFailed">
                                                <span>跟进记录：</span>
                                                <span>{{item.approvalResult}}</span>
                                            </p>      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
           </div>
            <!-- 区间弹窗 -->
            <van-calendar 
            v-model="calendarShow" 
            type="range"
            @confirm="selectDateInterval" 
            color="#00a0e4"
            :min-date="minDate" 
            :max-date="maxDate" />
       </div>
   </div>
</template>

<script>

import { getDateFky,dateChange,pickerFormatter,findIndex,cloneDeep } from "@/utils";
import back from "@/mixins/back";
import getStatusTextMixin from "@/mixins/getStatusText";
import permissionCheckMixin from "@/mixins/permissionCheck";

//接口
import {getOrderList,getOrderDetail} from "@/api/lottery"
export default {

    mixins:[back,getStatusTextMixin,permissionCheckMixin],

    computed:{

        //是否为开发商列表

        isSubDistributor(){

            return this.$route.name == 'subDistributorOrderList';

        },

        getStaffList(){

            return this.staffList || [{text:'不限',value:0}];

        },

        //报备状态列表

        orderStatusList(){

            return this.$store.state.userInfo?.orderStatusList || [{text:'不限',value:0}]

        },

        //角色

        role(){

            return this.$store.state.userInfo.role || ''

        },

        //公司主体
        companyName(){

            return process.env?.VUE_APP_SYSTEM_NAME == 'huimai' ? '海南慧麦房地产营销策划有限公司' : process.env?.VUE_APP_SYSTEM_NAME == 'fky' ? '海南房客云网络科技有限公司' : '暂无'

        }
        
    },

    data(){

        return{

            keyword:'',

            orderList:[],

            staffList:[],

            option2: [

                { text: '不限', value: 0 },

                { text: '本日', value: 1 },

                { text: '本周', value: 2 },

                { text: '本月', value: 4 },

                { text: '近3个月', value: 6 },

                { text: '近6个月', value: 7 },

            ],

            //是否到底

            finished:false,

            //是否加载

            loading:false,

            //是否下拉

            refreshing:false,

            //是否出错

            listError:false,

            //页码

            page:1,

            //一页请求的数据

            size:8,

            //日历展示

            calendarShow:false,


            //流程

            flowPathTitle:'流程',

            flowPathValue:-1,

            //报备时间

            orderStartTime:'',

            orderEndTime:'',

            orderTimeIndex:0,

            orderTimeTitle:'时间',

            orderTimeObj:{startDate:'',endDate:''},

            //经纪人

            staffTitle:'经纪人',

            staffValue:0,


            //打开时间选择弹窗

            showEndPicker:false,

            showStartPicker:false,

            minDate: new Date(2020, 0, 1),

            maxDate: new Date(),
            
            currentDate: new Date(),
            
            currentDateEnd: new Date(),
            
            

            orderStatusOptions:[],

            scrollTop:0


        }

    },


    methods:{

        //下拉加载

        onRefresh(){

            this.page=1;

            this.finished=true;

            this.getOrderList('refresh');

        },

        //搜索

        onSearch(){

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',
                forbidClick: true,
                duration:0

            })

            this.getOrderList('search');

        },

        //上拉更多

        onLoad(){

            this.getOrderList();

        },

        //获取列表

        getOrderList(type){

            let _options = {

                page:this.page,
                pageSize:this.size,
                keyword:this.keyword,
                status:this.flowPathValue,

            }

            //添加搜索时间参数

            if(this.orderTimeIndex > 0){

                //只有自选的时候才使用该参数

                if(this.orderTimeIndex == 6 && this.orderStartTime && this.orderEndTime){

                    _options.startDate = this.orderStartTime;

                    _options.endDate = this.orderEndTime;


                }else{

                    _options.startDate = this.orderTimeObj.startDate;

                    _options.endDate = this.orderTimeObj.endDate;

                }


            }

            getOrderList(_options).then((res)=>{

                this.listError=false;

                this.finished=res.data.list.length < this.size || res.data.list.length == 0;

                //对数据进行处理

                res.data.list.forEach(el => {
                    
                    //将数据提取出来

                    el?.clue && el?.clue?.formDetail.forEach(_el=>{

                        if(_el.type == 'name'){
                            el.name = _el.value;
                        }
                        
                    });

                    el.typeTxt = el?.clue?.typeTxt;
                    
                    el.area = el.clue?.area || '暂无';

                    el.houseName = el?.clue?.solutionName || '';

                });


                if(!this.finished){

                    this.page++;

                }

                //首先判断是否存在勾选状态的楼盘

                if(type == 'refresh' || type == 'search'){

                    this.$refs.orderListScrollView.scrollTop=0;

                    this.orderList = res.data.list;

                    this.$toast.clear();

                    this.refreshing=false;

                }else{

                    this.orderList.push(...res.data.list);

                }

            }).catch((err)=>{

                console.log(err);

                if(this.orderList.length == 0) this.listError = true;

                if(type == 'refresh' || type == 'search'){

                    this.$toast.fail(err?.message || '订单数据获取失败');

                }

            }).finally(()=>{

                if(type == 'refresh'){

                    this.refreshing=false;

                }
                    
                this.loading=false;
                
                this.$store.commit('setRouterData',{orderListRefreshId:0});

            })

        },

        
        //打开日历

        calendarOpen(){

            this.calendarShow=true;

        },

        //选择日历区间

        selectDateInterval(val){

            this.orderStartTime = dateChange(val[0],'date');

            this.orderEndTime = dateChange(val[1],'date');

            this.$nextTick(()=>{

                this.calendarShow = false;

            })

        },


        
        //个别数据更新

        orderUpdate(){  

            if(!this.$store.state.routerStore.orderListRefreshId || this.orderList.length == 0) return;

            getOrderDetail({id:this.$store.state.routerStore.orderListRefreshId})
            .then(res=>{

                    
                    res.data?.clue && res.data?.clue?.formDetail.forEach(_el=>{

                        if(_el.type == 'name'){
                            res.data.name = _el.value;
                        }

                    });
                    
                    res.data.area = res.data.clue?.area || '暂无';

                    res.data.houseName = res.data?.clue?.solutionName || '';

                    res.data.typeTxt = res.data?.clue?.typeTxt;

                    //检查是否存在对应的元素

                    let _index = findIndex(this.orderList,(el)=>{

                        return el.id == res.data.id;

                    });
                        
                    if(_index !=-1){

                        this.$set(this.orderList,_index,res.data);

                        this.$toast({

                            position:'bottom',

                            message:'已更新数据'

                        });

                    }
            })
            .catch(err=>{
                console.log(err);
            }).finally(()=>{
                  this.$store.commit('setRouterData',{
                        orderListRefreshId:null
                    });
            })

        },

        


        //选择流程下拉菜单

        flowPathChange(data){


            if(data == 0){

                this.flowPathTitle='流程'

            }else{

                this.flowPathTitle=''

            }

            this.$nextTick(()=>{

                this.onSearch();
            })
            

        },


        //选择时间

        selectDate(date){

            //自选

            if(date.value == 99 && (!this.orderStartTime || !this.orderEndTime)) return;

            if(date.value == 0){

                this.orderTimeTitle='时间'

            }else{

                this.orderTimeTitle=date.text;

            }

            
            this.orderTimeIndex = date.value;

            //获取时间区间

            if(date.value !=99 && date.value!=0){

                //拿到区间，转化为字符串

                let inv = getDateFky(date.value);
                
                this.orderTimeObj.startDate= dateChange(inv.start,'date');

                this.orderTimeObj.endDate = dateChange(inv.end,'date');

                this.orderStartTime = this.orderEndTime = '';


            }else{

                this.orderTimeObj.startDate = this.orderStartTime;

                this.orderTimeObj.endDate = this.orderEndTime;

            }

            this.$refs.order_date.toggle(false);
                
            this.$nextTick(()=>{

                this.onSearch();

            })

            
        },
        
        //打开起始时间区间

        openEndPicker(){

            this.showEndPicker=true;

        },

        //关闭起始时间区间
        closeEndPicker(){

            this.showEndPicker=false;

        },

        

        //打开结束时间区间

        openStartPicker(){

            this.showStartPicker=true;

        },

        closeStartPicker(){

            this.showStartPicker=false;

        },

        selectStartDate(val){

            this.orderStartTime = dateChange(val,'date');

            this.closeStartPicker();

        },        
        
        selectEndDate(val){

            this.orderEndTime = dateChange(val,'date');

            this.closeEndPicker();

        },

        //时间区间格式化

        formatter (type, value) {
            
            return pickerFormatter(type,value);

        },







        //点击复制

        onCopy(item){

            let copyMessage = `一级公司名称：${this.companyName}\n二级公司全称：${item.subdistributorName || '暂无'}\n带看员工：${item.dkStaffName || item.subdistributorStaffName}\n员工电话：${item.dkStaffMobiles || item.subdistributorStaffMobile}\n客户姓名：${item.name}\n客户电话：${item.mobiles}\n客户性别：${item.sex == 1 ?'男':'女'}\n到访人数：${item.visitorNum}\n带看项目：${item.houseName}\n到访时间：${item.estimatedVisitTime}\n指定接待：${item.reception || '暂无'}\n渠道对接人：${item?.channelName || '暂无'}\n渠道对接人号码：${item?.channelMobile || '暂无'}`

            this.$copyText(copyMessage).then(()=>{
                
                this.$toast({

                    message:'报备格式复制成功',

                    position:"bottom"

                });
    
                },()=>{
                
                this.$toast({

                    message:'复制失败，如果多次复制失败，请联系管理员',

                    position:'bottom'

                });

            })

        },




        linkTo(item){

            //每次跳转都设置一次跳转内容
            this.scrollTop=this.$refs.orderListScrollView.scrollTop;
            this.$router.push({path:`/order/detail/${item.id}`});

        },


    },


    //滚动状态监听

    activated(){

        this.$nextTick(()=>{
            
                if(this.orderStatusOptions.length == 0){

                    this.orderStatusOptions = this.orderStatusList;

                }

                if(this.scrollTop){

                    this.$refs.orderListScrollView.scrollTop=this.scrollTop;

                }

                //检查是否存在需要更新的数据
   

                this.orderUpdate();

        })

    },


    beforeDestroy(){

        this.scrollTop=0;
        
    }

}
</script>

<style lang="less">

.order-list-wrapper{

    height:100%;
    
    .order-list-cover{

        height:100%;

    }

    .multiple-toolbar-wrapper{

        position: absolute;

        z-index:66;

        bottom:12vh;

        right:30px;
        
        width: 40px;

        .multiple-toolbar-btn{

            width: 50px;

            height:50px;

            background:rgb(0, 160, 228);

            border-radius: 50%;
            
            text-align: center;

            line-height:50px;

            font-size:30px;

            color:#fff;

            opacity: .6;

            margin-bottom:15px;

            transition:opacity .3s;

            &:active{

                opacity: 1;

            }

        }

    }

    .hd{

        .menu{

           .date-interval{

               display: flex;

               padding:15px;

               align-items: center;

               .date-interval-item{

                   font-size:13px;

                   line-height:24px;

                   padding: 2px 8px 1px;

                   background:#f6f6f6;

                   color:#999;

                   &.confirm{

                    margin-left:40px;

                    padding: 2px 15px 1px;

                    background: #eee;

                    border-radius:3px;

                    border: 0;

                    &.live{

                        background: #00a0e4;

                        color:#fff;

                    }

                   }

               }

           }

        }

    }


    .bd{

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y: auto;

        background: #f6f6f6;

        .van-pull-refresh{

            min-height:100%;

        }

        .order-item{

            background: #fff;

            margin-top:10px;

            padding: 10px 15px;

            overflow: hidden;

            position: relative;

            .order-item-multiple-overly{

                position: absolute;

                z-index:10;

                left:0;

                top:0;

                right:0;

                bottom:0;

            }

            &:first-child{

                margin-top:0;

            }

            .order-item-cover{
                
                display: flex;

            }


            .order-item-l{

                margin-top:2px;

            }

            .button-cover{

                button{

                    margin-left:10px;

                    &:first-child{

                        margin-left:0;

                    }

                }

            }

            &.m-selected{

                box-shadow:0 0 8px rgba(0, 160, 228,.5) inset;

            }
            
            .order-item-r{

                flex:1;

                width: 0;

                padding-left:10px;

                .info{

                    padding-bottom:6px;

                    .customer{

                        display: flex;

                        justify-content: space-between;

                        align-items: flex-end;

                    }

                    .name{

                        font-size:16px;

                    }

                    .phone,.order-time{

                        font-size:12px;

                        color:#999;

                    }

                    .order-time.close{

                        position: relative;

                        &:after{

                            content:"";

                            position: absolute;

                            width: 50px;

                            height:50px;

                            background: url('../../assets/images/close.png') scroll no-repeat center center / 100% 100%;

                            right:-15px;

                            top:-20px;

                            transform: rotate(25deg);

                            z-index:10;

                        }

                    }

                    .phone{

                        vertical-align: bottom;

                        padding-left:8px;

                    }
                    
                    .house-name{

                        font-size:14px;

                        line-height:28px;

                        padding-top:3px;

                    }

                    .visit-date{

                        font-size:13px;

                        line-height:24px;

                    }

                    .label-item{

                        line-height:24px;

                        font-size:13px;

                        display: flex;
                        
                        span:nth-child(2){

                            flex: 1;

                            width: 0;

                        }

                    }

                    .staff-info{

                        line-height:24px;

                        font-size:13px;

                        display:flex;

                        align-items: center;

                        a{

                            flex:1;

                            width: 0;

                            position: relative;

                            z-index:30;

                            padding-left:5px;

                            .call-icon{

                                color:#00a0e4;

                                font-size:14px;

                                padding-left:2px;

                                position: relative;

                                top:1px;

                                transform: rotate(20deg);

                            }

                        }

                    }

                }

                .des{

                    padding-top:13px;

                    .order-type{

                        display: flex;

                        justify-content: space-between;

                        align-items: center;

                        .order-type-status{

                            font-size:14px;

                            color:#666;



                            span{


                                &.await{

                                    color:rgb(0, 160, 228,.6);

                                }

                                &.faild{

                                    color:rgba(252, 54, 54,.6);

                                }

                                &.success{

                                    color:rgb(3, 187, 3,.6);

                                }

                                &.warning{

                                    color:#ff976a;

                                }

                            }

                        }

                        .order-copy{

                            padding: 4px 10px 3px;

                            font-size:12px;

                            color:rgb(3, 187, 3);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(3, 187, 3);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(0, 255, 0,.05);

                            }

                        }

                        .order-again{

                            padding: 4px 10px 3px;

                            font-size:12px;

                            color:rgb(223, 112, 39);

                            border: 0;

                            background: transparent;

                            &:after{

                                border-color:rgb(223, 112, 39);

                                border-radius:5px;

                            }

                            &:active{

                                background: rgba(255, 145, 0, 0.05);

                            }

                        }

                    }

                }

            }

            .order-extra{

                padding-top:10px;

                .orderFailed{

                    color:#999;

                    display: flex;

                    align-items: flex-start;

                    span{
                        
                        line-height:18px;

                    }

                    span:nth-child(2){

                        flex:1;

                        width: 0;

                    }

                }

            }

        }
        

    }

    

}

</style>
