import Vue from "vue";
import Vuex from "vuex";
import userInfo from "@/store/modules/userInfo";
import commonForm from '@/store/modules/commonForm';
import routerStore from "@/store/modules/router"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    userInfo,
    commonForm,
    routerStore
  }
});
