import {isObject} from "@/utils";
import Vue from "vue";

const state = {

    //列表是否需要刷新

    orderListRefreshId:0,
    
    //是否进行完第一次跳转

    isFirstPush:false,
  
}

const mutations = {

    //设置数据
    setRouterData(state,data){
        if(!isObject(data)){
            console.error('data 必须要为一个对象');
            return;
        }
   
        Object.keys(data).forEach(el=>{
            Vue.set(state,el,data[el]);
        });
    }
}

const actions = {};
const getters = {};
export default {
    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters
}