
import login from "@/views/common/login";
import errPage from '@/views/common/404';
import lottery from "@/views/common/index";
const router = [
    {
        path:"/index",
        name:"index",
        component:lottery,
        meta:{
            lv:3
        }
    },
    { 
        path: '*',

        name:'404',

        component:errPage,

        meta:{

            lv:0

        }

    },{

        //登录

        path:"/login",

        name:'login',

        component:login

    }
]




export default router;