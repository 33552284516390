<template>
    <div class="order-site-url-wrapper">
        <div class="order-site-url-cover flex-box-column">
            <div class="hd">
                <van-nav-bar 
                left-arrow 
                safe-area-inset-top 
                title="广告落地页"
                @click-right="orderChange">
            </div>
            <div class="bd">
                <iframe :src="siteUrl" frameborder="0" v-if="siteUrl"></iframe>
                <div class="loading-tips" v-else>正在加载...</div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data(){
        return {
            siteUrl:''
        }
    },
    mounted() {
        this.siteUrl = this.$route.query.siteUrl;
    },


}
</script>

<style lang="less" scoped>
.order-site-url-wrapper{
    height:100%;
    background: #fff;
    .order-site-url-cover{
        height:100%;
        .bd{
            flex:1 1 auto;
            height:0;
        }
        iframe{
            width: 100%;
            height:100%;
        }
    }
}
</style>