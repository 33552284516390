<template>
<div class="old-lottery-btn" @click="gotoList"></div>
</template>
<script>
/**
 * 历史抢单数据按钮居按钮
 */
export default {

    methods:{
        gotoList(){
            this.$router.replace({path:"/order/list"})
        }
    }

}
</script>

<style lang="less" scoped>

.old-lottery-btn{

    position: fixed;

    bottom:50px;

    right:20px;

    width: 80px;

    height:80px;

    border-radius: 50%;

    text-align: center;

    line-height:60px;

    transform: opacity .3s;

    background:url('../../assets/images/index/list-btn.png') scroll no-repeat center center / 100% 100%;
    

    &:active{

        opacity: .6;

    }

}

</style>