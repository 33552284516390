import request from '@/utils/request';

/**
 * 验证此次抢单有效性
 * 
 */
export function lotteryCheck(params) {
  return request({
    url: `https://www.fastmock.site/mock/5616dec6f10e6b6845c2a37b1a97e1e8/qd/lotteryCheck`,
    method: 'post',
    data: params
  })
}


/**
 * 开始抢单
 * 
 */

export function lotteryStart(params) {
  return request({
    url: `/api/market/grab-clue`,
    method: 'post',
    data: params
  })
}


/**
 * 获取订单列表
 * 
 * @param {Number} page 页码
 * @param {Number} pageSize 每页展示数量
 * 
 */

export function getOrderList(params) {
  return request({
    url:"/api/market/clue-list-by-user",
    method:'post',
    params:params
  })
}

/**
 * 获取订单详情
 * 
 * @param {Number|String} id 详情页id
 */

 export function getOrderDetail(params) {
  return request({
    url:"/api/market/clue-detail",
    method:'post',
    data:params
  })
}

/**
 * 提交用户跟进记录
 * 
 * @param {Number} status 客户跟进状态
 * 
 * @param {String} remark 备注
 */

export function customerRecords(params) {
  return request({
    url:"/api/market/clue-follow-remark",
    method:'post',
    data:params
  })
}

/**
 * 更改订单状态
 * 
 */

export function orderStatusChange(params){
  return request({
    url:"/api/market/clue-follow",
    method:'post',
    data:params
  })
}