import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import eventBus from "@/eventBus"

//modules

import common from '@/router/modules/common';

//管理
import management from "@/router/modules/management";


//阻止弹出报错提示

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {

  return originalPush.call(this, location).catch(err => err)
  
}


Vue.use(VueRouter);

const routes = [

  ...common,

  ...management

];





const router = new VueRouter({

  mode: "history",

  base: '/',

  routes

});



router.beforeEach((to, from, next) => {
  
  if(!store.state.userInfo.show){
    
    next();

  }else{

    //当初始化完毕以后，访问登录页面，阻止访问
    if(to.name == 'login' && store.state.userInfo.ready){



      if(from.name !='login'){

        next({...from,replace:true});
        
      }else{

        next({path:'/order/get/customers',replace:true});

      }


    }else if(!store.state.userInfo.ready && to.name !='login' && !to.meta.live){

      console.log(111)

      //初始化未完成
      next({name:'login',replace:true});

    }else if(to.path == '/' || !to.path){

      //准备完毕

      if(store.state.userInfo.ready){

        if(from.name !='login'){

          //当准备完毕

          next({...from,replace:true});

        }else{

          //当准备完毕

          next({path:'/order/get/customers',replace:true});

        }

      }else{

        next({name:"login",replace:true});

      }
    

    }else{

      next();

    }

  

  }



})




//路由跳转完毕

router.afterEach(() => {

  if(!store.state.routerStore.isFirstPush){

    //监听是否为第一次加载
    store.commit('setRouterData',{
      isFirstPush:true
    });

    if(!store.state.userInfo.show){
      //触发初始化事件
      eventBus.$emit('appInit');
    };

  }

})


export default router;
