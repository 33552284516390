import dataStorage from "@/utils/dataStorage";

import sessionStorage from '@/utils/sessionStorage';

import {isArray} from "@/utils";

import {checkToken} from "@/api/user";

const state = {

   ready:false,

   show:false,

   token:"",

   orderStatusTextObj:{},

   orderStatusList:[{
        "value": -1, 
        "text": "不限"
    }, 
    {
        "value": 0, 
        "text": "未跟进"
    }, 
    {
        "value": 1, 
        "text": "跟进中"
    }, 
    {
        "value": 2, 
        "text": "有意向"
    }, 
    {
        "value": 3, 
        "text": "无意向"
    }]
    
  
}

const mutations = {

    //设置数据

    setUserData(state,{key,value,save}){
        
        state[key] = value;

        if(save){

            let options = {
                token:state.token
            }


            dataStorage.StorageSetter('user_common_data_beta',{token:state.token});
            sessionStorage.StorageSetter('user_common_data',options);

        }

    },

    //数据初始化

    initData(state,data){
        
        //序列化状态列表，以便直接提取对应的名称

        if(state?.orderStatusList && isArray(state.orderStatusList)){

                state.orderStatusList.forEach(el=>{

                    if(el.value !== ''){

                        state.orderStatusTextObj[el.value] = el.text;

                        
                    }

                })

        }

        //设置token

        state.token = data.token;

        //保存数据

        dataStorage.StorageSetter('user_common_data_beta',{token:data.token});

        //储存临时数据
        sessionStorage.StorageSetter('user_common_data',data);

        state.ready = true;

        var a = 'https://clue.fxyos.com/index?id=d9259b7e-5756-460d-bc36-b21490452a2d&groupId=354c473d-549a-471f-b805-e0a896ab6e95&page-key=75ba7e6b'

    },

    //重置数据

    resetUserData(state){

        //准备状态取消

        state.ready = false;

        state.token = null;

        dataStorage.StorageRemove('user_common_data_beta');

        sessionStorage.StorageRemove("user_common_data");

    }



}

const actions = {

    /**
     *   获取用户数据 , 数据获取成功后，即表示用户登录成功后，将数据储存在session storage 中
     *   刷新时检查此数据是否存在 如果存在就直接刷新，不存在就进行登录
     *   储存格式应当为 user-state 
     * 
     *   将常用的数据储存起来
     * 
     */

    getInitData({commit},{callback,path}){

        try{

            //首先映带判断是否存在原始数据

            let userCommonData = dataStorage.StorageGetter('user_common_data_beta');      

            let sessionCommonData = sessionStorage.StorageGetter('user_common_data');

            //设置token去进行数据请求,当存在临时数据的时候优先使用

            if(sessionCommonData){

                commit('initData',sessionCommonData);

                callback && callback({path:path},'success');

            }else{

                if(userCommonData?.token){

                    //设置一下token
                    commit('setUserData',{
                        key:'token',
                        value:userCommonData.token
                    });

                    //发起请求
                    checkToken().then((res)=>{

                        commit('initData',userCommonData);

                        callback && callback({path:path || '/index'},'success');

                    }).catch((err)=>{

                        console.log(err);

                        //清除数据
                        commit('resetUserData');

                        callback && callback({name:'login'},'error');

                    })

                  
        
                }else{
        
                    //不存在缓存数据的时候进行跳转登录

                    callback && callback({name:'login'},'error');
        
                }


            }


        }catch(err){

            console.log(err);

            commit('resetUserData');

            callback && callback({name:"login"},'error');

        }
        
    }

}

const getters = {}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }