import request from '@/utils/request';


/**
 * 获取授权地址
 * 
 * 
 */

 export function getWxAuth(params) {

  return request({
    url: `https://www.fastmock.site/mock/5616dec6f10e6b6845c2a37b1a97e1e8/qd/getWxAuth`,
    method: 'post',
    data: params
  });

}


/**
 * 登录
 *
 * @param {String} code 授权登录code
 * 
 */

/**
 * 校验登录
 * 
 * checkToken
 */

export function checkToken(params) {
  return request({
    url: `/api/login/check-token`,
    method: 'post',
    data: params
  })
}