<template>
<div class="lottery-wrapper">
    <div class="flex-box-column lottery-cover">
        <!-- <div class="hd">
            <van-nav-bar 
            safe-area-inset-top 
            title="抢订单">
            </van-nav-bar>
        </div> -->
        <div class="bd">
            <div class="title"></div>
            <div class="main">
                <div class="text" :class="{scale:text}">{{text}}</div>
                <div class="btn" @click="lotteryStart"></div>
                <div class="old" @click="oldListLinkTo" ref="oldBtn"></div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import oldListBtn from "@/components/common/oldListBtn";
import {lotteryStart} from '@/api/lottery';
import {TweenMax,Expo,Back} from "gsap";
export default {
    components:{
        oldListBtn
    },
    data(){

        return{
            
            //是否禁用点击按钮
            btnDisabled:false,
            btnText:'点我抢单',
            listShow:false,
            text:''
            
        }

    },

    methods:{

        lotteryStart(){

            if(!this.$store.state.routerStore?.accessCode || !this.$store.state.routerStore?.groupId){

                this.$dialog.alert({
                    message: '非法请求，请按照正确的流程进入抢单页面',
                    theme: 'round-button',
                    className:`lottery-tips error-tips`
                    }).then(() => {
                    // on close
                });

                return;

            }

            this.$toast.loading({
                message:'正在抢单...',
                duration:0,
                forbidClick:true
            });
            //发起请求
            lotteryStart({
                id:this.$store.state.routerStore?.accessCode,
                groupId:this.$store.state.routerStore?.groupId
            }).then((res)=>{
                this.text="恭喜你抢到一单";
                //跳转到最新的一个列表页
                global.setTimeout(()=>{
                    this.$router.replace({path:`/order/detail/${res.data.id}`});
                },1500)
            }).catch(err=>{
                if(err.code !=401){
                    this.$dialog.alert({
                        message: err.message,
                        theme: 'round-button',
                        className:`lottery-tips${err.code == 10005 ? '  error-tips' : ''}`
                    }).then(() => {});
                }
            }).finally(()=>{this.$toast.clear()});
            
        },

        oldListLinkTo(){

            let $button = this.$refs.oldBtn;
            let duration = 0.3,
                delay = 0.08;
                TweenMax.to($button, duration, {scaleY: 1.6, ease: Expo.easeOut});
                TweenMax.to($button, duration, {scaleX: 1.2, scaleY: 1, ease: Back.easeOut, easeParams: [3], delay: delay});
                TweenMax.to($button, duration * 1.25, {scaleX: 1, scaleY: 1, ease: Back.easeOut, easeParams: [6], delay: delay * 3 });

            this.$nextTick(()=>{

                this.$router.push({path:"/order/list"})

            })
        
        },



       
    },

}
</script>

<style lang="less">
.lottery-wrapper{

    height:100%;
    
    .lottery-cover{
        height:100%;

        .bd{

            flex:1;
            height:0;
            display: flex;
            flex-direction: column;
            align-items: center;
            background:url('../../assets/images/index/bg.jpg');
            user-select: none;
            .title{
                background: url('../../assets/images/index/css_sprites.png');
                width: 280.8px;
                height:72px;
                background-size:899.1px 670.5px;
                background-position:  -4.5px -475.65px;
                margin-top:20px;
            }

            .subTitle{
                background: url('../../assets/images/index/css_sprites.png');
                background-size:999px 750px;
                background-position: -329.5px -530.5px;
                width: 267px;
                height:46px;
                margin-top:18px;
            }

            .main{

                flex:1;

                height:0;
                
                width: 100%;

                margin-top:25px;

                background: url('../../assets/images/index/m_bg.png');

                background-size:100% 100%;

                position: relative;

                .text{
                    position: absolute;
                    left:50%;
                    width: 70%;
                    height:17%;
                    margin-left:-35%;
                    top:13.8%;
                    text-align: center;
                    font-size:28px;
                    text-shadow: 0px 2px 0px #FC5F00;
                    color:rgba(255, 241, 0, 1);
                    transform-origin:center center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.scale{
                        animation: scale .5s;
                    }
                }

                .btn{

                    position: absolute;
                    background: url('../../assets/images/index/css_sprites.png');
                    background-size:899.1px 675px;
                    background-position: -627.3px -330.75px;
                    width: 226px;
                    height:67px;
                    left:50%;
                    transform: translateX(-50%);
                    top:37%;

                    &:active{
                       background-position: -351px -330.75px;
                    }

                }

                .old{

                    position: absolute;
                    width: 123.3px;
                    height:116.1px;
                    left:50%;
                    bottom:5%;
                    transform: translateX(-50%);
                    background: url('../../assets/images/index/css_sprites.png');
                    background-size:899.1px 675px;
                    background-position:-4.5px -554.85px;

                }

            }
        }   

    }

    @keyframes scale {
        from{

            transform: scale(0,0);

        }
        to{
            transform: scale(1,1);
        }
    }
}
.lottery-tips{
    
    width: 318px;
    height:365px;
    background-color:transparent;
    background-image: url('../../assets/images/index/css_sprites.png');
    background-size:999px 750px;
    background-position: -687px 0;
    .van-dialog__content{
        padding: 140px 24px 10px;
        height:253px;
        overflow: hidden;
        .van-dialog__message{
            font-size:21px;
            line-height:28px;
            text-shadow: 0px 2px 0px rgba(0,0,0,.3);
            color:rgba(255, 241, 0, 1);
        }
    }
    .van-dialog__footer{
        background:transparent;
        padding-top:20px;
        justify-content: center;
        button{
            font-size:0;
            background:transparent;
            width: 214px;
            height:65px;
            background-image: url('../../assets/images/index/css_sprites.png');
            background-size:999px 750px;
            background-position: -696px -448px;
            flex:0 1 auto;
            border-radius: 30px;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            &:active{
                background-position: -696px -448px;
               
            }
        }
    }


    &.error-tips{

        background-position: -379px 0;

    }

}
</style>