<template>
<div class="lottery-fail-wrapper">
    <div class="lottery-fail-cover flex-box-column">
        <div class="hd">
            <van-nav-bar 
            safe-area-inset-top 
            title="抢单失败">
            </van-nav-bar>
        </div>
        <div class="bd">
            <van-empty description="很抱歉，该单已经抢完" />
        </div>
    </div>
    <oldListBtn></oldListBtn>
</div>
</template>
<script>
import oldListBtn from "@/components/common/oldListBtn"
export default {

    components:{
        oldListBtn
    }


}
</script>

<style lang="less" scoped>
.lottery-fail-wrapper{
    &,.lottery-fail-cover{
        height:100%;
    }
    .bd{
        flex:1;
        height:0;
        padding: 15px;
        .message-cover{
            
            width: 100%;

            height:200px;

            line-height:200px;
            
            text-align: center;

            margin-top:-100px;

            font-size:16px;

            background: #f6f6f6;
        }
    }

}
</style>