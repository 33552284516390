import axios from 'axios'
import store from "@/store";
import eventBus from '@/eventBus'
// 创建axios实例
const service = axios.create({
  baseURL: 'https://test.fxyos.com/' || '',
  timeout: 15000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  config.headers['Authorization'] = store.state.userInfo.token || '';
  return config;
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 200) {
      // 401重新登陆
      if(res.code == 401){
        eventBus.$emit('toastShow',{
          duration:3000,
          message:res.message,
          position:'bottom'
  
        })
        //触发退出登录事件
        eventBus.$emit('tokenExpired');
      }else if(res.code ==500){
        eventBus.$emit('toastShow',{
          duration:3000,
          message:res.message,
          position:'bottom'
  
        })
      }

      return Promise.reject(res)
    }else {
      return response.data
    }
  },
  error => {
    console.log(error.message);
    eventBus.$emit('toastShow',{
      message: error.message,
      duration: 3 * 1000
    })

    return Promise.reject(error)
  }
)

export default service
