<template>
   <div class="login-wrapper">
       <div class="scroll-view">
           <div class="flex-cover top">
                <div class="logo"><img src="@/assets/images/login_logo.png" ></div>
            </div>
            <div class="login-form">
                    <p class="tips">抢单前，请先登录</p>
                    <div class="login-btn" @click="init">授权登录</div>
            </div>
       </div>
   </div>
</template>

<script>
import circleBtn from "@/components/common/circle-btn";
export default {

    components:{
        
        circleBtn

    },
    
    data(){

        return {

            username:'',

            password:'',

            checked: false,

            passwordShow:false

        }


    },


    computed:{

        backRouteName(){

            return this.$store.state.userInfo.backRouter;

        },
        

    },

    methods:{

        login(token){

           this.$store.commit('initData',{token});

           //跳转到抢单也
           this.$router.replace({path:'/index'});

        },

        init(){
            this.$toast.loading({
                message:'正在授权...',
                duration:0,
                forbidClick:true
            })  
            //判断是否为token请求页
            if(this.$route.query.token){
                this.login(this.$route.query.token);
            }else{
                this.$nextTick(()=>{
                    //是否已经初始化页面
                    if(!this.$store.state.userInfo.ready && this.$store.state.routerStore?.groupId){
                        //组装并重定向
                        let href  = global.location.protocol+'//'+global.location.host+'/login';
                        window.location.href=`https://test.fxyos.com/api/login/enterprise-wechat?callbackUrl=${href}&groupId=${this.$store.state.routerStore?.groupId}`;
                    }else{
                        this.$toast.clear();
                        this.$dialog.alert({
                        title: '请求失败',
                        message: '不存在组id，请通过正确的入口进入抢单页面',
                        }).then(() => {});
                    }
                })
            }
        }
    },

    activated(){
        this.init();
    },
    

}
</script>

<style lang="less">
.login-wrapper{

    height:100%;

    .scroll-view{

        height:100%;

        overflow: hidden;

        overflow-y:auto;

        padding-bottom:30px;

    }

        .top{

            display: flex;

            flex-direction: column;

            justify-content: center;

            align-items: center;

            min-height:30vh;

        }

        .logo{

            width: 200px;

            height:200px;

            position: relative;

            left:-5px;

            img{

                width: 100%;
                
                height:100%;

                

            }

        }

        .m-10{

            margin-top:10px;

        }

        .title{

            text-align: center;

            p{

                display: inline-block;

                padding: 6px 20px;

                font-size:16px;

                color:#ffffff;

                border-radius:25px;

                &.main-color_bg_hm{
                    background: #ffae00;
                }

                &.main-color_bg{
                    background:#00a0e4;
                }

            }

   

        }

        .login-form{

            padding: 0 60px;

            margin-top:30px;

            .form-icon{

                font-size:22px;

            }

        }

        .tips{

            text-align: center;

            color:#999999;

            padding-top:20px;

            padding-bottom:10px;

        }

        .login-btn{

            height:40px;

            line-height:40px;

            text-align: center;

            font-size:18px;

            color:#ffffff;
            
            background-image:linear-gradient(to right,#00a0e4,#0471ff);

            border-radius:25px;

            transition:opacity .3s ease;

            &:active{

                opacity: .6;

            }

        }

        .login-icon{

            font-size:22px;

            margin-right:10px;

        }

        .van-cell{

            font-size:16px;

            .van-field__control{

                padding-top:3px;

            }

        }
        
        .toolbar{

            display: flex;

            justify-content: center;

            padding-top:15px;

            .item{

                font-size:13px;

                color:#00a0e4;

                padding: 0 10px;

                opacity: .8;

            }
            

        }

        .login-save{

            padding: 10px 15px 0;

            .item{

                color:#00a0e4;

                font-size:13px;

            }

            .van-checkbox__icon{

                font-size:16px;

            }

            .van-checkbox__label{

                font-size:14px;

                position: relative;

                color:#666;


            }

            .active .van-checkbox__label{

                color:#00a0e4;

            }

        }

    }
</style>
