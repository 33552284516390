import _ from "lodash";






const isArray = (item)=>{

   return Object.prototype.toString.call(item) == '[object Array]';

}

const isObject = (item)=>{

   return Object.prototype.toString.call(item) == '[object Object]';

}

const isFn = (item)=>{

   return Object.prototype.toString.call(item) == "[object Function]"

}


const isString = (item)=>{

  return Object.prototype.toString.call(item) == '[object String]';

}

//是否为微信浏览器

const isWeex= ()=>{

   var ua = navigator.userAgent.toLowerCase();
   if(ua.match(/MicroMessenger/i)=='micromessenger') {
   return true;
   } else {
   return false;
   }

}

//是否为苹果端

const isIOS = ()=>{var u = navigator.userAgent;
   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
   return isiOS;}


//判断一个对象是否为空

const isObjectNone = (item)=>{

   return Object.keys(item).length == 0;

}

//判断一个变量是否为数字

const isNumber = (item)=>{

   return _.isNumber(item);

}

//判断一个字符串或这数字是否为手机号吗

const isPhone = (item)=>{
   
   const phoneReg = /^(1[3-9])\d{9}$/;

   return phoneReg.test(item);

}




const omitBy = (item,fn)=>{

   return _.omitBy(item,fn);

}


//对象比较

const isEqual = (item,item2)=>{

   return _.isEqual(item,item2);

}

//克隆元素

const cloneDeep = (item)=>{

   return _.cloneDeep(item);

}

//获取匹配到的第一个元素的下标

const findIndex = (item,fn)=>{

   return _.findIndex(item,fn);

}

//删除数组中的指定元素

const pull =(arr,item)=>{

   return _.pull(arr,item);

}


//过滤对象

const pickBy = (obj,fn)=>{

   return _.pickBy(obj,fn);

}

//合并数组

const merge = (arr1,arr2)=>{

   return _.merge(arr1,arr2);

}

//合并去重数组

const differenceBy = (arr1,arr2,options)=>{

   return _.differenceBy(arr1,arr2,options)

}

//提取数组交集

const intersection =(arr)=>{

   return _.intersection(...arr);

}

//提取数组交集,可调用方法

const intersectionWith = (arr,arr1,fn)=>{

   return _.intersectionWith(arr,arr1,fn);

}

//去重

const uniq =(item,item2,callback)=>{

   let _data;

   if(isArray(item) && !item2){

      _data =  _.uniq(item);

   }else{

      _data = {};
      
      Object.keys(item).forEach(el=>{

         let _item = item[el];

         //特殊处理

         callback && callback({

            //原数据

            item,

            //比较数据

            item2,

            //当前循环到的数据

            el,

            //返回的数据

            _data


         });

         //不一样才能添加

         if(!isEqual(item2[el],_item)){

            _data[el]=_item;

         }
         

      })

   }

   return _data;

}

/**
 * 
 * 时间格式化
 * 
 * 1 本日  2 本周  3 上周  4 本月  5 上月
 * 
 * 6 最近三个月  7 最近六个月  8 本年
 * 
 */

const getDateFky = (type)=>{

   //本日

   if(type == 1){

      const end = new Date();

      const start = new Date();

      return {end,start}
      
   }else if(type == 2){

      //本周

      const end = new Date();

      const start = new Date();

      start.setTime(start.getTime() - ((start.getDay() > 0 ? start.getDay() : start.getDay()+7) - 1)*3600 *24 *1000);

      return {end,start}

   }else if(type == 3){

      //上周

      const date = new Date();

      date.setHours(0,0,0);
    
      //按自然周计算，开始时间应当是自然周的第一天
    
      const start = new Date();
    
      const end = new Date();
          
      start.setTime(date.getTime() - (date.getDay() + (7 - 1 - 0)) * (24 * 60 * 60 * 1000));
    
      end.setTime(date.getTime() - (date.getDay() + (7 - 1 - 6)) * (24 * 60 * 60 * 1000));
    
      return {end,start}



   }else if(type ==4){

      //本月

      const end = new Date();

      const start = new Date();
      
      start.setDate(1);
      
      return {end,start}


   }else if(type == 5){

      //上个月

      const date = new Date();

      const start = new Date();

      const end = new Date();

      start.setMonth(date.getMonth()-1,1);

      end.setMonth(date.getMonth(),0);

      return {end,start}


   }else if (type == 6){
            
      //最近三个月

      const end = new Date();

      const start = new Date();

      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);

      return {end,start}

   }else if(type == 7){

      //最近6个月

      const end = new Date();

      const start = new Date();
      
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
      
      return {end,start}

   }else if (type == 8){

      //本年

      const end = new Date();

      const start = new Date(end.getFullYear(),0,1);

      
      return {end,start};

   }


}

const getDateText =(type)=>{

   const dateList = {

      0:'不限',

      1:'本日',

      2:"本周",

      3:'上周',

      4:'本月',

      5:'上个月',

      6:'最近三个月',

      7:'最近六个月',

      8:'本年'

   }


   return dateList[type];

}

/**
 *
 * 转化时间格式
 * 
 * @param _val 时间戳
 * 
 * @param type 获取的类型  有 date 和 time 默认为date+time 
 *
 */

const dateChange = (_val,type)=>{

   let val = new Date(_val);

   let year = val.getFullYear();
   let month = val.getMonth() + 1;
   let day = val.getDate();
   let hour = val.getHours();

   let minute = val.getMinutes();

   if (month >= 1 && month <= 9) { month = `0${month}` };

   if (day >= 1 && day <= 9) { day = `0${day}` };

   if (hour >= 0 && hour <= 9) { hour = `0${hour}` };

   if (minute >= 0 && minute <= 9) { minute = `0${minute}` };

   if(type == 'date'){

      return `${year}-${month}-${day}`;

   }else if(type == 'time'){

      return `${hour}:${minute}`

   }else{

      return `${year}-${month}-${day} ${hour}:${minute}`;

   }

}

const pickerFormatter =(type, value)=>{
   if (type === 'year') {
       return `${value}年`
   } else if (type === 'month') {
       return `${value}月`
   } else if (type === 'day') {
       return `${value}日`
   } else if (type === 'hour') {
       return `${value}时`
   } else if (type === 'minute') {
       return `${value}分`
   } else if (type === 'second') {
       return `${value}秒`
   }
   return value
   }

//过滤空值

const filterNull= (item,fn)=>{

   fn = fn ? fn : ()=>{return true};

   if(isArray(item)){

      item.filter(el=>{

         return !!el || fn(el);

      });

      return item;

   }


   if(isObject(item)){

      let _item = {};

      Object.keys(item).forEach(el=>{

         if(!!item[el] || fn(item[el])){

            _item[el] = item[el];

         }


      });


      return _item;


   }




}

/**
 * 
 * 获取基于当前屏幕的高度比例，可以用vh代替
 *
 * @param {number} num 需要获取的高度比例 0-1
 *
 */

const getScreenHeight=(num)=>{

   return Math.ceil(document.documentElement.clientHeight * num);

}


/**
 *
 * 将vw 转化为px
 * 
 * @params vw 需要转化的值，必须为数字
 *
 */

 const vwToPx = (vw)=>{
   
   let n =  document.documentElement.clientWidth / 100;

   return parseFloat((vw * n).toFixed(2));

 }


 /**
  * 只保留数字
  * 
  */

  const keepNumber = (data)=>{

   if(!data) return '';

   if(isNumber(data)) data = data+'';

   return data.replace(/[^0-9]*/g,'');

  }

  /**
   * 
   * 清除空格和斜杠
   * 
   */

   const replaceBlank = (data)=>{

      if(!data) return '';

      if(isNumber(data)) data = data+'';

      return data.replace(/[ ]|[ ]|[/-]/g,'');

   }


/**
 * 
 * 将 px 转化为 vw
 * 
 * @params px 需要转化的值，必须为数字
 */

 const pxToVw = (px)=>{

   let n = document.documentElement.clientWidth / 100;

   return parseFloat((px / n).toFixed(2));

 }



/**
 *
 * 递归获取数组中的值 
 *
 */

const readNodes = (nodes = [],fn,arr=[])=> {

   for (let item of nodes) {
      
       if(fn(item)){

         arr.push(item);

       }

       if (item.children && item.children.length) readNodes(item.children, fn,arr)

   }

   return arr
}

/**
 * 
 * 递归获取父级元素
 */

const readParentNodes =  (nodes = [],fn,arr=[]) => {

   for (let item of nodes) {

       if(fn(item)){

         arr.push(nodes);

       }

       if (item.children && item.children.length && arr.length == 0) readParentNodes(item.children, fn,arr)

   }

   return arr
}

/**
 * 递归删除对应元素
 */

const removeNodes =  (nodes = [],fn) => {

   if(!isArray(nodes)) return;

   nodes = nodes.filter(el=>{

      return fn(el);

   })

   nodes.forEach(el=>{

      el.children = el.children.filter(item=>{

         return fn(item);

      });

      if (el.children && el.children.length) removeNodes(el.children, fn)

   })

   return nodes;


}



/**
 * 
 * 一些工具的集合
 * 
 */



/**
   * 获取图片宽高
   * 
   * @param {string} src 图片地址
   * 
   * @return {object} w:图片宽度 ， h:图片高度
*/

const getImgSize = (src)=>{

   return new Promise((res,rej)=>{
 
      let img = document.createElement('img');
 
      img.src=src;
 
      //等待图片加载事件完成
 
      img.onload=function(){
 
         res({w:img.width,h:img.height});
 
      }
 
      //加载失败事件
 
      img.onerror=function(err){
 
         rej(err);
 
      }
 
   })
 
}

 
 /**
  *
  * 下载文件通用 
  *
  */

  const download = (url,callback)=>{

   url= url.replace(/\\/g, '/');

   const xhr = new XMLHttpRequest();

   const saveAs = (data, name)=>{

      var urlObject = window.URL || window.webkitURL || window;

      var export_blob = new Blob([data]);

      var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')

      save_link.href = urlObject.createObjectURL(export_blob);

      save_link.download = name;

      save_link.click();

   }
   
   const getFileName = (url)=> {

      var num = url.lastIndexOf('/')+1

      var fileName = url.substring(num);

      //把参数和文件名分割开
         
      fileName = decodeURI(fileName.split("?")[0]);

      return fileName;

   }

   xhr.open('GET', url, true);

   xhr.responseType = 'blob';

   xhr.onload = () => {

     if (xhr.status === 200) {

       // 获取文件blob数据并保存

       var fileName = getFileName(url);

       saveAs(xhr.response, fileName);

       callback && callback();

     }else{

      callback && callback('err');

     }

   };

   xhr.send();

}

 





 /**
  * 将菜单子数组合并到一个数组
  * @param {*} menu  菜单数组
  * @param {*} key   子数组的key名
  * 
  * @return {Array} menuList 返回一个对象
  */

 const getMenuList = (menu,key)=>{

   let _key = key || 'submenu'

   if(!isArray(menu)){

      console.error('menu必须为一个数组');

      return {};

   }

   let menuList = {};


   menu.forEach(el=>{

      el[_key] && el[_key].forEach(_el=>{

         menuList[_el.path] = _el.name;

      })

   });

   return menuList;

 } 
 




 /**
  * 
  * 获取格式化的时间
  * 
  * @param date 时间戳
  * 
  * @param separator 分隔符，不填默认'-'
  * 
  * @return date 格式化后的时间区间
  * 
  */

  const getFormatDate = ({date,separator,format,br})=>{

   let _date = new Date(date);

   let _separator = separator || '-';

   if(format == 'date'){


      return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 <10 
         
         ? '0' +(_date.getMonth() + 1) 
         
         : _date.getMonth() + 1
      
      }${_separator}${
         
         _date.getDate() <10 ? '0' +_date.getDate() : _date.getDate()}`

   }else{

      if(format == 'minute'){

         return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 <10 ? '0' +(_date.getMonth() + 1) : _date.getMonth() + 1}${_separator}${_date.getDate() <10 ? '0' +_date.getDate() : _date.getDate()}${br ? '<br>' : '  '}${_date.getHours() <10 ? '0'+_date.getHours() : _date.getHours()}:${_date.getMinutes() <10 ? '0'+_date.getMinutes() : _date.getMinutes()}`


      }else{

         return `${_date.getFullYear()}${_separator}${_date.getMonth() + 1 <10 ? '0' +(_date.getMonth() + 1) : _date.getMonth() + 1}${_separator}${_date.getDate() <10 ? '0' +_date.getDate() : _date.getDate()} ${_date.getHours() <10 ? '0'+_date.getHours() : _date.getHours()}:${_date.getMinutes() <10 ? '0'+_date.getMinutes() : _date.getMinutes()}:${_date.getSeconds() <10 ? '0'+_date.getSeconds() : _date.getSeconds()}`

      }
      
      


   }


  }



  /**
   * 
   * 分隔获取到的区域列表
   * 
   * @param {Array} 一个区域的数据
   * 
   * @return {renderList,searchCityList} 
   * 
   * 返回一个对象，
   * 
   * renderList array 用于渲染的数组
   * 
   * searchCityList Object 以id为key 的对象    
   * 
   * 
   */

   const initCityList = (cityList)=>{

      if(!isArray(cityList)){

         console.error('城市列表必须唯一个数组');

         return {};

      }

      let checkList = [];

      cityList.forEach((el,index)=>{

         el.children.forEach(_el=>{

            if(_el.id != el.id){

               checkList.push(Object.assign(_el,{areaIndex:index}));

            }

         })

      });


      return checkList;


   }



   
  /**
   * 获取对应的城市数据
   * 
   * @param {object} store 实例对象
   * 
   * @param {string}  城市名称
   * 
   * @param {string} id 城市id 如果同时存在城市名称和id，将会使用id进行检索
   * 
   * @return {object | null} cityData 返回一个城市对象 , 查询不到将会返回 null
   * 
   * 
   */

   const getCityData = (store,{id,name})=>{

      if(!isObject(store)){

         console.error('store 应当为一个对象');

         return null;

      }

      if(id){

         //获取对应的数据

         let index = findIndex(store.state.userInfo.checkCityList,(item)=>{

             return item.id == id;

         });

         if(index != -1){

             return store.state.userInfo.checkCityList[index];
             
         }else{

             return null;

         }
         
     }else if(name){

         //获取对应的数据

         let index = findIndex(store.state.userInfo.checkCityList,(item)=>{

            return name.indexOf(item.text) != -1 ;

         });

         if(index != -1){

             return store.state.userInfo.checkCityList[index];
             
         }else{

             return null;

         }
         
     }else{

         return null;

     }


   }

   /**
    * 
    * @param {String} sHex 十六进制的颜色 
    * @param {*} alpha  透明度
    * @returns 
    */

   const colorRgba = (sHex, alpha = 1) => {
      // 十六进制颜色值的正则表达式
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      /* 16进制颜色转为RGB格式 */
      let sColor = sHex.toLowerCase()
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          var sColorNew = '#'
          for (let i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
          }
          sColor = sColorNew
        }
        //  处理六位的颜色值
        var sColorChange = []
        for (let i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
        }
        // return sColorChange.join(',')
        // 或
        return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')'
      } else {
        return sColor
      }
    }


 export{

   isArray,

   isObject,

   isFn,

   getMenuList,

   isObjectNone,

   getFormatDate,

   initCityList,
   
   isEqual,

   cloneDeep,

   findIndex,

   isNumber,

   pull,

   merge,

   uniq,

   readNodes,

   readParentNodes,

   removeNodes,

   omitBy,

   isString,

   filterNull,

   download,

   getImgSize,

   isPhone,

   getScreenHeight,

   vwToPx,

   getDateFky,

   dateChange,

   pickerFormatter,

   differenceBy,

   intersection,

   isWeex,

   pickBy,

   getCityData,

   getDateText,

   pxToVw,

   keepNumber,

   replaceBlank,

   intersectionWith,

   isIOS,

   colorRgba

 }