
import orderList from "@/views/management/orderList";

import orderDetail from "@/views/management/orderDetail";

import lotteryFail from "@/views/management/lotteryFail";

import orderSiteUrl from "@/views/management/orderSiteUrl";

const router = [

    {
        //订单列表

        path:'/order/list',

        name:'orderList',

        component:orderList,

        meta:{
            
            lv:5
        
        }


    },{
        //订单详情

        path:'/order/detail/:id',

        name:'orderDetail',

        component:orderDetail,

        props:true,

        meta:{lv:6}


    },{

        //抢单失败

        path:"/order/failed",

        name:"orderFailed",

        component:lotteryFail,

        meta:{

            lv:4

        }
    },{

        //落地页面

        path:"/order/siteUrl",

        name:"orderSiteUrl",

        component:orderSiteUrl,

        meta:{

            lv:7

        }
    }
]




export default router;