<template>
    <div class="layout" v-if="show">
         <transition :name="transitionName">
                <vue-page-stack>
                    <router-view class="router-view-c" :key="$route.fullPath"></router-view>
                </vue-page-stack>
         </transition>

    </div>
</template>

<script>
import sessionStorage from "@/utils/sessionStorage";
import dataStorage from "@/utils/dataStorage";
export default {

    data(){

        return{

            transitionName: 'fade',

            lock:false

        }

    },

    computed:{
        show(){
            return this.$store.state.userInfo.show;
        }
    },

    methods:{

        //初始化方法
        getInitData(){

            dataStorage.StorageRemove('user_common_data');
            
            this.$toast.loading({

                message:'加载中...',

                forbidClick:true,

                duration:0

            });

            this.$store.dispatch('getInitData',{

                    callback:(route,type,err)=>{

                        this.$toast.clear();

                        this.$store.commit('setUserData',{key:'show',value:true});

                        this.$nextTick(()=>{

                            if(err){

                                console.log(err);
                        
                                this.$toast.fail(err || '获取初始化数据失败');

                            }

                            //当页面必须登录时才显示此处

                            if(!this.$route.meta.live){

                                if(type == 'error'){

                                    if(this.$route.name !='login'){

                                        this.$router.replace(route);

                                    }

                                }else{

                                    if(!this.$route.path || this.$route.path == '/' || this.$route.name == 'login'){

                                        this.$router.replace(route);

                                    }

                                }

                            }
                            

                        });

                    },

                    path:this.$route.path && this.$route.path !='/' && this.$route.path !='/login' ? this.$route.path : '/index',

                    nowPath:this.$route.path,

                    isLive:this.$route.meta.live

            });



        },

        //获取当前抢单的序列
        sequenceInit(){

            if(this.$route.query?.id && this.$route.query?.groupId){
                //读取一下当前的抢单序列，储存在session中
                this.$store.commit('setRouterData',{accessCode:this.$route.query?.id,groupId:this.$route.query?.groupId});
                //储存到对应的状态
                sessionStorage.StorageSetter('accessCode',{id:this.$route.query?.id,groupId:this.$route.query?.groupId});
            }else{
                const data = sessionStorage.StorageGetter('accessCode');
                if(data){
                    //储存到对应的状态
                    this.$store.commit('setRouterData',{
                        accessCode:data.id,
                        groupId:data.groupId
                    });
                }
            }

        }

    },

    watch:{

        $route(to,from){

            if(from.name == 'login' && to.name !='login'){
                this.$toast.clear();
            }

            if(to.params.dir=='forward'){

                this.transitionName = 'forward';

                return;

            }

            if(to.params.dir == 'back'){

                this.transitionName = 'back';

                return;

            }

            if(!from.name && to.meta.module){

                this.transitionName = 'fade';

                return;

            }


            if(to.name == from.name){

                this.transitionName = 'replace';

                return;

            }

            if(!from.path){

                this.transitionName = 'fade';

                return;

            }


            if(to.name == '404'){

                this.transitionName = 'forward';

                return;

            }

            //未登录状态下的login判断

            if(!this.$store.state.userInfo.ready){

                //前进为前进，后退为后退

                if(to.name == 'login'){

                    if(to.params['page-key-dir'] === 'forward'){

                        this.transitionName = 'forward';

                    }else{

                        this.transitionName = 'back';

                    }

                    return;

                }

                if(from.name == 'login'){

                    if(to.params['page-key-dir'] === 'forward'){

                        this.transitionName = 'forward';

                    }else{

                        this.transitionName = 'back';

                    }

                    

                    return;

                }
                


            }

            
            if((to.name == 'login' || from.name == 'login') && !from.meta.live){

                this.transitionName = 'forward';

                return;

            }

            if(to.name == 'login' && from.meta.live){

                this.transitionName = 'back';

                return;

            }

            if(to.meta.ban && from.meta.ban){

                this.transitionName = 'fadeb';

            }else{

                if(to.meta.lv < from.meta.lv){

                    this.transitionName = 'back';

                }else{

                    
                    if (to.params['page-key-dir'] === 'forward') {

                        this.transitionName = 'forward';

                    } else {

                        this.transitionName = 'back';

                    }

                }


            }


            
        }

    },

    created(){
        
        this.sequenceInit();

        if(this.$store.state?.routerStore?.isFirstPush){

            this.$nextTick(()=>{

                this.getInitData();

            })

        }else{

            this.$eventBus.$once('appInit',()=>{

                this.$nextTick(()=>{

                    this.getInitData();

                })

            })

        }

        //监听token过期

        this.$eventBus.$on('tokenExpired',()=>{

            if(this.lock) return;
            this.$toast.clear();
            this.lock=true;
            this.$store.commit('setUserData',{
                key:"show",
                value:true
            });

            this.$store.commit('resetUserData');

                    this.$nextTick(()=>{

                        this.lock=false;

                        if(this.$route.name !='login'){

                            this.$router.replace({name:'login'});

                        }

                    })

        });

        /**
         * 监听toast主动弹出
         * 
         * 因为toast 不能在别的文件中调用
         */

        this.$eventBus.$on('toastShow',(options)=>{

            this.$toast(options);

        })
    }

}
</script>

<style scoped lang="less">
.layout{

    min-height:100%;

    width: 100%;

    position: relative;


    .router-view-c{

        position: absolute;

        left:0;

        width: 100%;
        
        top:0;

        transition: opacity 0.3s, transform 0.3s;

        overflow: hidden;

    }

        .fade-enter-active, .fade-leave-active {
            transition: opacity 0s;
            opacity: .5;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
        }

        .forward-enter,
        .back-leave-active {
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
        transform: translateX(100%) translateZ(0);
        }

        .forward-leave-active,
        .back-enter {
            opacity: 0.5;
            transform: translateX(-100%) translateZ(0);
        }


        .replace-enter-active,.replace-leave-active,.replace-enter,.replace-leave-to{

            .router-view-c{transition: all 0 ease 0;}

        }


}

</style>
