<template>
   <div class="order-detail-wrapper">
    <div class="order-detail-cover flex-box-column">
           <div class="hd">
            <van-nav-bar 
            left-arrow 
            safe-area-inset-top 
            title="订单详情" 
            :right-text="!status || status === 0 ? '' : orderChangeTitle"
            @click-left="back"
            @click-right="orderChange">
            </van-nav-bar>
      </div>
      <div class="bd" ref="bd">
        <van-pull-refresh v-model="loading" @refresh="onRefresh">
          <div class="customer-info van-hairline--bottom">
              <div class="customer-info-cover">
                <div class="customer-avater avatar secret"></div>
                <div class="ctn">
                    <div class="des van-hairline--bottom">
                        <div class="name">{{customerName || '客户'}}<span class="phone f-12">{{customerPhone}}</span></div>
                        <div class="call">
                            <a v-if="customerPhone && status !== 0" :href="'tel:'+customerPhone" @click.stop><van-icon name="phone" class="call-icon"></van-icon></a>
                        </div>
                    </div>
                    <div class="customer-info-item first" v-if="customerLikeHouse">
                        <span>意向楼盘：</span>
                        <span>{{customerLikeHouse}}</span>
                    </div>
                    <div class="customer-info-item" v-if="customerArea">
                        <span>客户地区：</span>
                        <span>{{customerArea}}</span>
                    </div>  
                    <div class="customer-info-item" v-if="typeTxt">
                        <span>线索来源：</span>
                        <span>{{typeTxt}}</span>
                    </div>  
                    <div class="customer-info-item" v-if="siteUrl">
                        <span>落 地 页：</span>
                        <span @click="goSiteUrl" class="site-link">点此查看</span>
                    </div>  
                </div>
              </div>
          </div>
          <div class="select-order-status__wrapper van-hairline--bottom">
              <div class="title">跟进状态（请及时调整状态）</div>
              <div class="select-order-status__cover">
                  <div 
                  class="radio-btn" 
                  v-for="(item,index) in statusOptions" 
                  :key="index"
                  :class="{active:item.value == status,disabled:item.value === 0 && status !== 0}"
                  @click="statusChange(item.value)">{{item.label}}</div>
              </div>
          </div>
          <div class="tabs">
            <van-tabs v-model="tabsActive" swipeable animated sticky offset-top="12.26667vw">
                <van-tab title="跟进记录">
                    <div class="order-cards tabs-item" :style="getMinHeight">
                        <template v-for="(item,index) in orderData.follow">
                            <div class="order-card" :key="index" v-if="orderData.follow && orderData.follow.length>0">
                                <div class="order-card__title">
                                    <span class="await">{{item.remark}}</span>
                                    <span class="date">{{item.updatedAt}}</span>
                                </div>
                            </div>
                        </template>
                        <div class="order-card none" v-if="!orderData.follow || orderData.follow == 0">暂无对应信息</div>
                    </div> 
                </van-tab> 
            </van-tabs>
        </div>
        </van-pull-refresh>
      </div>
       </div>
       <!-- 跟进记录单状态修改 -->
       <van-dialog v-model="orderDialogShow" title="添加跟进记录" :beforeClose="orderDialogConfirm" show-cancel-button confirmButtonColor="#00a0e4" confirmButtonText="提交">
            <div class="order-change-dialog border_top">
                <van-form>
                    <van-field
                    v-model="orderAddForm.remark"
                    label-width="0"
                    rows="3"
                    autosize
                    type="textarea"
                    placeholder="留言请写在此处请写在此处"
                    />
                </van-form>
            </div>
       </van-dialog>
       <!-- 选择状态 -->
        <oldListBtn></oldListBtn>
   </div>
</template>

<script>
import backMixin from "@/mixins/back";
import { vwToPx,isObjectNone,isArray,isObject } from "@/utils";
import getStatusTextMixin from "@/mixins/getStatusText";

//接口
import {getOrderDetail,customerRecords,orderStatusChange} from "@/api/lottery";
import oldListBtn from "@/components/common/oldListBtn"
export default {

    mixins:[backMixin,getStatusTextMixin],

    components:{
        oldListBtn
    },

    data(){

        return {

            loading:false,

            tabsActive:0,

            minHeight:0,

            orderDialogShow:false,

            orderAddForm:{},

            showOrderPicker:false,

            pickerDefaultIndex:0,

            inited:false,

            orderData:{

                list:[],

                userOrderList:[]
            },

            statusOptions:[

                {
                    value:0,
                    label:"未跟进"
                },{
                    value:1,
                    label:'跟进中'
                },{
                    value:2,
                    label:'有意向'
                },{
                    value:3,
                    label:'无意向'
                }

            ]

           
        }

    },

    props:{

        id:{
            type:String|Number,
            default:''
        }

    },

    computed:{

        getMinHeight(){

            return {minHeight:this.minHeight+'px'};

        },

        /**
         * 
         * 跟进记录单状态显示。
         * 
         * 跟进记录专员在跟进记录单不为关闭时显示审核，否则显示查看，则无法更改跟进记录单
         * 
         * 跟进记录经理全部状态都能修改所以是审核
         * 
         * 渠道可以添加动态和修改信息
         * 
         */

        orderChangeTitle(){

            return '添加跟进记录'

        },

        orderImages(){

            return this.orderData?.images || [];

        },

        //用户名称

        customerName(){

            return this.orderData?.name || '';

        },

        //客户意向楼盘
        customerLikeHouse(){
            return this.orderData?.clue?.campaignName || ''
        },

        //用户区域
        customerArea(){
            return this.orderData?.clue?.area ? this.orderData?.clue?.area.replace('/n',' ') : '';
        },

        customerPhone(){
            return this.orderData?.phone || '';
        },  

        //订单状态
        status(){
            return !this.orderData?.status && this.orderData?.status!==0 ? null : this.orderData?.status
        },

        typeTxt(){
            return this.orderData?.clue?.typeTxt || '-'
        },

        //广告落地页地址
        siteUrl(){
            return this.orderData?.clue?.siteUrl || '';
        }
    },

    methods:{


        //添加跟进记录

        orderChange(){

            this.orderDialogShow=true;

        },


        //上传跟进记录动态

        orderDialogConfirm(action,done){

            if(action == 'confirm'){

                customerRecords({id:this.id,...this.orderAddForm})
                .then((res)=>{

                        this.orderAddForm = {};
                        this.$dialog.alert({

                                title:'提交成功',

                                message:`跟进记录添加成功`,

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{

                                this.$nextTick(()=>{

                                    this.onRefresh();
                                
                                })
                                

                            });
                        
                        this.$store.commit('setRouterData',{
                            orderListRefreshId:this.id
                        })
                        done(true);
                        
                })
                .catch(err=>{
                    this.$dialog.alert({

                        title:'提交失败',

                        message:err || '提交失败，请稍后重试',

                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{});
                    done(false);
                })
            }else{

                done(true);

            }

           

        },

        //获取跟进记录单信息

        getOrderData(isLoading){

            if(!isLoading){

                this.$toast.loading({

                    message:'加载中...',

                    duration:0,

                    overlay:true

                });

            };

            getOrderDetail({id:this.id}).then((res)=>{

                if(!isObject(res.data)){

                    this.$dialog.alert({

                        title:"获取详情失败",
                        message:"无法获取到相应的订单详情",
                        confirmButtonColor:"#00a0e4"

                    }).then(()=>{})

                }

                //对返回数据进行处理 

                isArray(res.data?.clue?.formDetail) &&  res.data.clue.formDetail.forEach(el => {

                    //仅取，电话以及名称两种
                    if(el.type == 'name'){

                        res.data.name = el.value;

                    }
                    
                });
                

                this.orderData = res?.data || {};

                this.$set(this.orderAddForm,'status',this.orderData.status);

                this.inited=true;


            }).catch((err)=>{

                console.log(err);

                this.$dialog.confirm({

                    title:'订单数据获取失败',

                    message:err.message || `订单数据获取失败${res.type !=700 ? '，点击确认进行重新加载' :''}`,

                    confirmButtonColor:"#00a0e4"

                }).then(()=>{

                    this.getOrderData();

                }).catch(()=>{})

            }).finally(()=>{

                this.$toast.clear();

                this.loading=false;

            })

        },

        init(){
            this.getOrderData();
        },

        //选择操作

        onConfirm(data){
            this.$set(this.orderAddForm,'status',data.value);
            this.showOrderPicker=false;
        },

        //下拉刷新

        onRefresh() {
           this.getOrderData(true);
        },

        //切换按钮
        statusChange(status){
            if(status == 0 || (status == 0 && this.orderData.status !==0)) return;
            this.$toast.loading({
                duration:0,
                forbidClick:true,
                message:'正在切换...'
            });

            orderStatusChange({
                id:this.id,
                status,
            }).then(()=>{
                this.onRefresh();
                //记录刷新
                this.$store.commit('setRouterData',{
                    orderListRefreshId:this.id
                });
            }).catch(err=>{
                if(err.code != 401){
                    this.$dialog.alert({
                    title:'切换失败',
                    message:err.message
                    }).then(()=>{});
                }
            }).finally(()=>{this.$toast.clear()});

        },

        //前往广告落地页
        goSiteUrl(){
            this.$router.push({path:"/order/siteUrl",query:{siteUrl:this.siteUrl}})
        }


    },

    mounted(){
        

        this.$nextTick(()=>{
            this.minHeight = this.$refs.bd.offsetHeight-vwToPx(10.26667);
        })

    },

    activated(){

        if(!this.$store.state.routerStore.orderListRefresh || isObjectNone(this.orderData) || this.$store.state.routerStore.orderListRefresh != this.$route.params.id) return;

        this.getOrderData();

    },

    created(){
        this.$nextTick(()=>{

            this.getOrderData();

        })

    }

}
</script>

<style lang="less">
.order-detail-wrapper{

    height:100%;

    background: #f6f6f6;

    .site-link{
        text-decoration: underline;
    }

    .select-order-status__wrapper{
        background: #fff;
        margin:0 10px 10px;
        padding:15px 20px;
        
        .title{
            font-size:15px;
            margin-bottom:12px;
            position: relative;
            font-weight: 500;
            color:#575757;
            &:after{
                content:'';
                position: absolute;
                left:-10px;
                top:0;
                width: 3px;
                bottom:0;
                height:80%;
                margin: auto 0;
                background: #00a0e4;
            }
        }

        .select-order-status__cover{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .radio-btn{

            height:31px;

            line-height:31px;

            width: 72px;

            text-align: center;

            font-size:13px;

            color:#999999;

            border-radius: 4px;

            border:1px solid rgba(0,0,0,.2);

            &:active{
                
                border-color:rgba(0,0,0,.3);

                color:#666666;

            }

            &.active{
                
                background: #00a0e4;
 
                color:#fff;
            
                border-color:#00a0e4;


            }

            
                &.disabled{

                    background:transparent;

                    &:active,&{

                        border-color:rgba(0,0,0,.1);

                        color:#b9b9b9;

                    }

                }

        }
    }

    .toolbar-wrapper{

        height:100%;

        display: flex;

        justify-content: center;

        align-items: center;

        .toolbar-cover{

            width: 200px;

            height:200px;

            background:#fff;

            border-radius:5px;

        }

    }

    .order-detail-cover{

        height:100%;

    }

    .bd{

        padding-top:10px;

        flex:1;

        height:0;

        overflow: hidden;

        overflow-y:auto;

        height:100%;

        .order-mobile{

            position: relative;

            top:2px;

        }

        .steps-wrapper{

            padding-bottom:15px;

        }

        .customer-info{

            padding: 0 10px 15px;

            &.steps-show{

                padding-bottom:5px;

            }

            .customer-info-cover{

                background: #00a0e4;

                color:#fff;

                padding: 15px;

                border-radius:5px;

                display: flex;

                align-items: flex-start;

                .ctn{

                    margin-left:15px;

                    flex:1;

                    width: 0;

                    .des{

                        display: flex;

                        justify-content: space-between;

                        width: 100%;

                        .name{

                            font-size:15px;

                            .phone{

                                padding-left:5px;

                            }

                        }

                        .call{
                            min-height:30px;
                            a{

                                display: block;

                                height:30px;

                                width: 30px;

                                text-align: center;

                                line-height:30px;
                                
                                position: relative;

                                z-index:10;

                            }

                            .call-icon{

                                color:#fff;

                                font-size:18px;

                            }

                        }

                    }

                    .customer-info-item{

                        padding-bottom:3px;

                        font-size:13px;

                        display: flex;

                        line-height:20px;

                        &.first{
                            padding-top:8px;
                        }

                        span:nth-child(2){
                            
                            flex:1;

                            width: 0;

                        }

                    }

                }

            }

        }

        .tabs{

            .van-sticky--fixed{
                max-width: 10rem;
                margin: 0 auto;
            }
            
            .van-tabs__nav{
                position: relative;
                           color:#575757;
                &:after{
                    content:'';
                    position: absolute;
                    left:0;
                    top:0;
                    height:100%;
                    background:#00a0e4;
                    width: 5px;
                }
            }

            .van-tab--active{

                color:#333333;

                justify-content: flex-start;

                padding: 0 30px;

                font-size:15px;

            }

            .van-tabs__line{

                width: 125px;

                background-color:#00a0e4;

                height:1px;

                display: none;

            }

            .tabs-item{

                padding: 15px 15px 30px;

                &.order-cards{

                    .order-card{

                        background: #fff;

                        padding: 15px;

                        border-radius:5px;

                        margin-bottom:15px;
                        
                        &.none{

                            background: transparent;

                            color:#999999;

                            text-align: center;

                            font-size:14px;

                        }

                        .order-card__title{

                            font-size:15px;

                            display: flex;

                            align-items: flex-start;

                            justify-content: space-between;

                            flex-direction: column-reverse;

                            width: 100%;
                            
                            span:first-child{

                                &.faild{

                                    color:rgb(252, 54, 54);

                                }

                                &.success{

                                    color:rgb(3, 187, 3);

                                }

                                
                                &.await{

                                    color:rgb(0, 160, 228,.6);

                                }

                            }

                            .date{

                                color:#999999;

                                font-size:13px;

                                line-height:20px;

                                padding-bottom:6px;

                            }

                        }

                        .order-card_content-cell{

                            font-size:14px;

                            line-height:28px;

                            span{

                                color:#666666;

                            }

                            .cell-name{

                                color:#999999;

                            }

                            .call-icon{

                                color:#00a0e4;

                                margin-left:5px;

                                font-size:17px;

                                position: relative;

                                top:2px;

                            }

                        }

                    }

                }

                &.order-images{

                    .order-images-cover{

                        padding: 15px;

                        background:#fff;

                        min-height:120px;

                        border-radius:5px;

                        overflow: hidden;

                        .order-images-show{

                            display: flex;
                            
                            flex-wrap: wrap;

                            justify-content: space-between;

                        }

                        .order-image{

                            width: 150px;

                            height: 150px;

                            margin-bottom:10px;

                            box-shadow:0 5px 16px rgba(0,0,0,.16);

                            position: relative;

                            overflow: hidden;

                            img{

                                width: 100%;

                                height:100%;

                            }

                            .close-btn{

                                position: absolute;
                                width: 80px;
                                height: 40px;
                                right: -54px;
                                top: -18px;
                                background: rgba(0, 0, 0, 0.6);
                                text-align: center;
                                padding-top: 15px;
                                transform: translateY(10px) translateX(-26px) rotate(45deg);

                                color:#fff;

                                .delete-icon{

                                    font-size:18px;

                                    text-shadow: 0 0 16px rgba(0,0,0,.16);

                                }
                                
                                &:active .delete-icon{

                                    opacity: .6;

                                }

                            }

                        }

                        .van-uploader__upload{

                            width: 150px;

                            height:150px;
                            

                            .van-uploader__upload-icon{

                                font-size:30px;

                            }

                        }

                    }

                }

            }

            

        }

    }

    .order-change-dialog{

        padding: 15px;

        z-index:15;

        margin-top:15px;

        .van-form{

            .van-cell{

                z-index:20;

            }

        }

    }

}
</style>
