<template>
<div class="circle-btn-wrapper" :class="{active:btnAcitve&&!btnDisabled,disabled:btnDisabled}" :style="wrapperColor" @click="animate" ref="circleBtn">
    <div class="circle-btn-cover" :style="coverColor">
        <div class="circle-btn" :style="btnColor" ><p class="btn-text"><slot></slot></p></div>
    </div>
</div>
</template>
<script>
import {colorRgba} from "@/utils";
import {TweenMax,Expo,Back} from "gsap";
import throttle from "@/mixins/throttle";
export default {
    mixins:[throttle],
    props:{
        color:{
            type:String,
            default:'#007aff'
        },
        btnDisabled:{
            type:Boolean,
            default:false
        }
    },

    data(){

        return{

            btnAcitve:true

        }

    },

    computed:{

        wrapperColor(){

            return `background:${colorRgba(this.color,.1)}`;

        },

        coverColor(){

            return `background:${colorRgba(this.color,.3)}`;

        },
        
        btnColor(){

            return `background:${colorRgba(this.color,1)}`;

        }

    },

    methods:{

        animate(){ 

            if(this.btnDisabled) return;

            //删除掉对应的方式

            this.btnAcitve=false;

            this.$nextTick(()=>{
            let $button = this.$refs.circleBtn;
            let duration = 0.3,
                delay = 0.08;
                TweenMax.to($button, duration, {scaleY: 1.6, ease: Expo.easeOut});
                TweenMax.to($button, duration, {scaleX: 1.2, scaleY: 1, ease: Back.easeOut, easeParams: [3], delay: delay});
                TweenMax.to($button, duration * 1.25, {scaleX: 1, scaleY: 1, ease: Back.easeOut, easeParams: [6], delay: delay * 3 });
            })

            this.$emit('click');

            this.throttle({
                key:'circleBtn',
                time:2000,
                callback:()=>{
                    this.btnAcitve=true;
                }
            })

        }

    }

}
</script>

<style lang="less" scoped>
.circle-btn-wrapper{

    width: 200px;

    height:200px;

    padding: 15px;

    border-radius:50%;



      &.active{
        position: relative;
        animation: fade 1s ease-in-out;
        transition: transform .5s;
            &:after{
                 content:'';
                text-indent: -999px;
                 z-index:-1;
                position: absolute;
                 width: 100%;
                 height:100%;
                        left:50%;
                  top:50%;
                 margin-left:-50%;
                 margin-top:-50%;
                 border-radius: 50%;
                 background-color:inherit;
                 animation: breathe 3s infinite ease-in-out .5s;
                 opacity: 0;
            }

        
    }

    &.disabled{

        background:transparent!important;

        .circle-btn-cover{

            background:transparent!important;

            .circle-btn{

                background: rgba(221, 221, 221,1)!important;

            }

        }

    }

    .circle-btn-cover{

        width: 100%;

        height:100%;

        padding: 15px;
        
        border-radius:50%;

        .circle-btn{

            width: 100%;

            height:100%;
            
            border-radius:50%;

            display: flex;
            
            justify-content: center;

            align-items: center;

        }
    }

    .btn-text{

        font-size:18px;

        color:#fff;

    }

     @-webkit-keyframes breathe{
            0% {
                -webkit-transform:scale(.5);
                -webkit-opacity:.9;
            }
            95%{
                -webkit-transform:scale(1.8);
                -webkit-opacity:0;
            }
            100%{
                -webkit-transform:scale(.9);
                -webkit-opacity:0;
            }
        }

}
</style>